import { Colors } from '@/classes/colors/Colors'
import VueApexCharts from 'vue-apexcharts'

export default {
  props: ['campaginDataSource'],
  name: 'DeliveredMailing',
  components: { apexchart: VueApexCharts },
  data: function () {
    return {
      ready: true,
      colors: Colors,
      deliveredChart: {
        percentage: 0,
        series: [],
        options: {},
      },
      showEmpty: false,
      emptySeries: [100],
      emptyChartOptions: {
        labels: [''],
        legend: {
          show: false,
        },
        fill: {
          colors: [Colors.colorChartEmptyBar],
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            dataLabels: {
              name: {
                fontSize: '16px',
                color: undefined,
                offsetY: 120,
              },
              value: {
                offsetY: 76,
                fontSize: '22px',
                color: undefined,
                formatter: function (val) {
                  return '' // val + '%'
                },
              },
            },
          },
        },
      },
    }
  },
  methods: {
    calculateUndeliveredTotal (data) {
      let fixedUndeliveredTotal = parseInt(data.hard_bounced_unique) + parseInt(data.soft_bounced_unique)
      if (fixedUndeliveredTotal < 0) {
        fixedUndeliveredTotal = 0
      }
      return fixedUndeliveredTotal
    },
    calculateUnopened (data) {
      return parseInt(data.delivered_total) - parseInt(data.opened_unique)
    },

    // eslint-disable-next-line max-lines-per-function
    setPieChart (totals) {
      if (totals === undefined) {
        return
      }

      // delivered chart
      totals.delivered_total = parseInt(totals.delivered_total)
      totals.undelivered_total = this.calculateUndeliveredTotal(totals)
      totals.unopened = this.calculateUnopened(totals)

      this.deliveredChart.percentage = totals.delivered_total === 0 ? totals.delivered_total : ((totals.delivered_total / totals.sent_total) * 100).toFixed(0)
      this.deliveredChart.series = [totals.delivered_total, totals.undelivered_total]
      const undeliveredPercentage = ((totals.undelivered_total / totals.sent_total) * 100).toFixed(0)

      this.deliveredChart.dataInfo = [
        {
          label: this.$t('No entregados').toString(),
          value: undeliveredPercentage + '%',
          color: Colors.colorChartUndelivered,
        },
      ]
      this.deliveredChart.options = {
        legend: {
          show: false,
        },
        colors: [Colors.colorChartDelivered, Colors.colorChartUndelivered, Colors.colorChartOpened],
        labels: [this.$t('Entregados').toString() + ' ' + this.deliveredChart.percentage + '%', this.$t('No Entregados').toString() + ' ' + undeliveredPercentage + '%'],
      }
      this.resetReady()
    },

    resetReady () {
      this.ready = false
      setTimeout(() => {
        this.ready = true
      })
    },

  },
  created () {
    const additional = JSON.parse(JSON.stringify(this.campaginDataSource.additional))
    if (this.campaginDataSource.total > 0) {
      this.setPieChart(additional[0])
    } else {
      this.showEmpty = true
    }
  },
}
