// import { Colors } from '@/classes/colors/Colors'
import VueApexCharts from 'vue-apexcharts'
import HelpText from '@/components/Layout/HelpText/HelpText.vue'

export default {
  props: ['campaginDataSource', 'campaign', 'height'],
  name: 'UserInteractionSmsRate',
  components: { apexchart: VueApexCharts, HelpText },
  data: function () {
    return {
      ready: true,
      heightDef: this.height ? this.height : 220,
      clicksRatio: 0,
      clickUnique: 0,
      totalClick: 0,
    }
  },
  methods: {
    setBarChart (data) {
      const totalDelivered = data.delivered_total ? parseInt(data.delivered_total) : 0
      this.totalClick = data.clicked_total ? parseInt(data.clicked_total) : 0
      this.clickUnique = data.clicked_unique ? parseInt(data.clicked_unique) : 0
      if (totalDelivered > 0) {
        this.clicksRatio = (this.totalClick / totalDelivered * 100).toFixed(2)
      }
    },
  },
  created () {
    if (this.campaginDataSource.data[0]) {
      this.setBarChart(this.campaginDataSource.data[0])
    }
  },
}
