import { Colors } from '@/classes/colors/Colors'
import VueApexCharts from 'vue-apexcharts'

export default {
  props: ['campaginDataSource', 'campaign', 'height'],
  name: 'UserInteractionMailing',
  components: { apexchart: VueApexCharts },
  data: function () {
    return {
      ready: true,
      heightDef: this.height ? this.height : 220,
    }
  },
  computed: {
    barChartOptions () {
      return {
        chart: {
          type: 'bar',
          toolbar: {
            show: false,
          },
        },
        annotations: {
          position: 'front',
          yaxis: [{
            label: {
              borderColor: '#c2c2c2',
              text: this.$t('Media del total de los entregados').toString(),
              },
            },
          ],
        },

        series: [
          {
            name: this.$t('Abiertos'),
            data: [],
          },
          {
            name: this.$t('Clics'),
            data: [],
          },
          {
            name: 'Soft Bounces',
            data: [],
          },
          {
            name: 'Hard Bounces',
            data: [],
          },
          {
            name: this.$t('Bajas'),
            data: [],
          },
        ],
        tooltip: {
          shared: true,
          intersect: false,
        },
        colors: [
          Colors.colorBarOpened,
          Colors.colorBarClicked,
          Colors.colorBarSoftBounced,
          Colors.colorBarHardBounced,
          Colors.colorBarUnsubscribed,
        ],
        dataLabels: {
          enabled: true,
          enabledOnSeries: undefined,
          formatter: function (val) {
              return val
          },
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top',
            },
          },
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return value
            },
          },
        },
        xaxis: {
          categories: [],
        },
      }
    },
    locale () {
      return this.$i18n.locale
    },
  },
  watch: {
    locale () {
      this.setBarChart(this.campaginDataSource.data)
    },
  },
  methods: {
    setBarChart (data) {
      data.forEach(data => {
        const deliveredTotal = parseInt(data.delivered_total)
        const openedUnique = parseInt(data.opened_unique)
        const clickedUnique = parseInt(data.clicked_unique)
        const softBouncedUnique = parseInt(data.soft_bounced_unique)
        const hardBouncedUnique = parseInt(data.hard_bounced_unique)
        const unsubscribedTotal = parseInt(data.unsubscribed_total)

        const openedUniquePercentage = deliveredTotal ? (openedUnique / deliveredTotal).toFixed(1) : 0
        const clickedUniquePercentage = deliveredTotal ? (clickedUnique / deliveredTotal).toFixed(1) : 0
        const softBouncedUniquePercentage = deliveredTotal ? (softBouncedUnique / deliveredTotal).toFixed(1) : 0
        const hardBouncedUniquePercentage = deliveredTotal ? (hardBouncedUnique / deliveredTotal).toFixed(1) : 0
        const unsubscribedTotalPercentage = deliveredTotal ? (unsubscribedTotal / deliveredTotal).toFixed(1) : 0


        this.barChartOptions.series[0].data.push(openedUniquePercentage)
        this.barChartOptions.series[1].data.push(clickedUniquePercentage)
        this.barChartOptions.series[2].data.push(softBouncedUniquePercentage)
        this.barChartOptions.series[3].data.push(hardBouncedUniquePercentage)
        this.barChartOptions.series[4].data.push(unsubscribedTotalPercentage)
        this.barChartOptions.xaxis.categories.push(data.sending_id)
      })
      this.resetReady()
    },

    resetReady () {
      this.ready = false
      setTimeout(() => {
        this.ready = true
      })
    },
  },
  created () {
    this.setBarChart(this.campaginDataSource.data)
  },
}
