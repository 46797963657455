import VueApexCharts from 'vue-apexcharts'
import { Colors } from '@/classes/colors/Colors'

export default {
  props: ['campaginDataSource'],
  name: 'UnsubscribedWhatsapp',
  components: { apexchart: VueApexCharts },
  data: function () {
    return {
      ready: true,
      colors: Colors,
      unsubscribedPercentage: 0,
      unsubscribedTotal: 0,
      series: [100],
      chartOptions: {
        chart: {
          height: 250,
          type: 'radialBar',
          offsetY: -10,
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            dataLabels: {
              name: {
                fontSize: '16px',
                color: undefined,
                offsetY: 120,
              },
              value: {
                offsetY: 76,
                fontSize: '22px',
                color: undefined,
                formatter: function (val) {
                  return '' // val + '%'
                },
              },
            },
          },
        },
        fill: {
          colors: [Colors.colorChartUnsubscribedLanding],
          opacity: 0.9,
          type: 'solid',
        },
        stroke: {
          dashArray: 4,
        },
        labels: [''],
      },
      showEmpty: true,
      emptySeries: [100],
      emptyChartOptions: {
        labels: [''],
        legend: {
          show: false,
        },
        fill: {
          colors: [Colors.colorChartEmptyBar],
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            dataLabels: {
              name: {
                fontSize: '16px',
                color: undefined,
                offsetY: 120,
              },
              value: {
                offsetY: 76,
                fontSize: '22px',
                color: undefined,
                formatter: function (val) {
                  return '' // val + '%'
                },
              },
            },
          },
        },
      },
    }
  },
  methods: {
    setUnsubscribedTotals (totals) {
      totals.unsubscribed_total = parseInt(totals.unsubscribed_total)
      totals.delivered_total = parseInt(totals.delivered_total)
      const unsubscribedPercentage = (totals.unsubscribed_total / totals.delivered_total) * 100
      this.unsubscribedPercentage = unsubscribedPercentage > 0 ? unsubscribedPercentage.toFixed(2) : '0'
      this.unsubscribedTotal = totals.unsubscribed_total
      this.series = [this.unsubscribedPercentage]
      // console.log(this.unsubscribedPercentage)
      if (this.unsubscribedPercentage === '0') {
        this.showEmpty = true
      }
      this.resetReady()
    },
    resetReady () {
      this.ready = false
      setTimeout(() => {
        this.ready = true
      })
    },
  },
  created () {
    // const additional = JSON.parse(JSON.stringify(this.campaginDataSource.additional))
    // if (this.campaginDataSource.total > 0) {
    //   this.setUnsubscribedTotals(additional[0])
    // } else {
    //   this.showEmpty = true
    // }
  },
}
