import VueFunnelGraph from '@/components/Graphs/VueFunnel/VueFunnelGraph.vue'
import GeneralStatsService from '@/services/general-stats.service'
import EventBus from '@/util/EventBus.js'

export default {
    name: 'Funnel',
    components: { VueFunnelGraph },
    props: ['globalControl'],
    data () {
      return {
        switchDiaHoraLabel: 'Por día de la semana',
        daysOrHour: true,
        subLabels: [],
        values: [
          [1, 1, 1],
          [1, 1, 1],
          [1, 1, 1],
        ],
        colors: [
          [this.$voiceColor],
          [this.$emailColor],
          [this.$smsColor],
        ],
        direction: 'horizontal',
        gradientDirection: 'horizontal',
        height: 200,
        width: 520,
        dataSetNum: 1,
        subLabelValue: 'percent',
        type: 'area',
        isFunnelEmpty: true,
        render: true,
      }
    },
    computed: {
      labels () {
        return [this.$t('Enviados').toString(), this.$t('Impactados').toString(), this.$t('Interacciones').toString()]
      },
    },
    methods: {
        setSublabelByChannel (channel) {
          // eslint-disable-next-line max-depth
          switch (channel) {
            case 'sms':
              this.subLabels.push('SMS')
            break
            case 'mailing':
              this.subLabels.push(this.$t('Mailing').toString())
            break
            case 'voice-interactive':
              this.subLabels.push(this.$t('Voz').toString())
            break
            case 'whatsapp':
              this.subLabels.push(this.$t('WhatsApp').toString())
            break
          }
        },

        hasNeededProps (key, data) {
          // eslint-disable-next-line dot-notation
          return data[key]['sent'] !== undefined && data[key]['impacted'] !== undefined && data[key]['interacted'] !== undefined
        },

        setFunnelColorByKey (key) {
          if (key === 'sms') {
            this.colors.push(this.$smsColor)
          }

          if (key === 'mailing') {
            this.colors.push(this.$emailColor)
          }

          if (key === 'voice-interactive') {
            this.colors.push(this.$voiceColor)
          }

          if (key === 'whatsapp') {
            this.colors.push(this.$whatsappColor)
          }
        },

        // eslint-disable-next-line complexity
        setStatsData (data) {
          this.colors = []
          this.subLabels = []
          // eslint-disable-next-line dot-notation
          if (data['sms'].length === 0 && data['mailing'].length === 0 && data['voice-interactive'].length === 0 && data['whatsapp'].length === 0) {
            this.globalControl.isFunnelEmpty = true
            return
          }

          let index = 0
          for (const key in data) {
            // eslint-disable-next-line dot-notation
            if (data[key].length !== 0 && this.hasNeededProps(key, data)) {
              this.values[0][index] = data[key].sent
              this.values[1][index] = data[key].impacted
              this.values[2][index] = data[key].interacted
              index++
              this.setSublabelByChannel(key)
              this.setFunnelColorByKey(key)
            }
          }

          if (index === 0) {
            this.globalControl.isFunnelEmpty = true
            return
          }

          const graphContainer = document.getElementById('funnel-graph-identifier')
          if (graphContainer) {
            const bounds = graphContainer.getBoundingClientRect()
            this.width = bounds.width
            this.globalControl.isFunnelEmpty = false
            this.render = false

            setTimeout(() => {
              this.render = true
            })
          }
        },
        getFunnelData () {
          if (!this.globalControl.initDate || !this.globalControl.endDate) {
            return
          }

          GeneralStatsService.getFunnelStats({
            initDate: this.globalControl.initDate,
            endDate: this.globalControl.endDate,
            globals: this.globalControl.globals,
          }).then((data) => {
            this.setStatsData(data)
          })
        },
    },
    created () {
      this.getFunnelData()
      EventBus.$on('updateFunnelStatisticsGlobalControl', () => {
        this.getFunnelData()
      })
    },
}
