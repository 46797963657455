import EventBus from '@/util/EventBus.js'
import { ChannelType } from '@/models/ChannelType'
import CampaignService from '@/services/campaign.service'

export default {
  name: 'HomeModalAction',
  data () {
    return {
      show: false,
      loading: false,
      currentAction: false,
      modalData: {},
      currentItem: {},
    }
  },
  computed: {
    strings () {
      return {
        duplicate: {
          title: this.$t('Duplicar campaña'),
          text: this.$t('¿Deseas duplicar esta campaña y crear un nuevo envío?'),
          buttonColor: 'light-green darken-1',
          buttonText: this.$t('Duplicar'),
        },
        pause: {
          title: this.$t('Pausar envío'),
          text: this.$t('¿Quieres pausar el envío seleccionado?'),
          buttonColor: 'light-green darken-1',
          buttonText: this.$t('Pausar'),
        },
        stop: {
          title: this.$t('Detener envío'),
          text: this.$t('¿Quieres detener el envío seleccionado?'),
          buttonColor: 'light-green darken-1',
          buttonText: this.$t('Detener'),
        },
        resume: {
          title: this.$t('Reanudar envío'),
          text: this.$t('¿Quieres reanudar el envío seleccionado?'),
          buttonColor: 'light-green darken-1',
          buttonText: this.$t('Continuar'),
        },
        cancel: {
          title: this.$t('Cancelar envío'),
          text: this.$t('Confirma que quieres cancelar el envío seleccionado'),
          buttonColor: 'red darken-1',
          buttonText: this.$t('Confirmar'),
        },
        delete: {
          title: this.$t('Eliminar campaña'),
          text: this.$t('¿Deseas eliminar la campaña?') + ' ' + this.$t('Todos los datos asociados a esta campaña serán eliminados'),
          buttonColor: 'red darken-1',
          buttonText: this.$t('Eliminar'),
        },
      }
    },
  },
  methods: {
    toggle () {
      this.show = !this.show
    },
    close () {
      this.toggle()
    },
    confirm () {
      const params = {
        sendingId: this.currentItem.sendingId,
      }
      switch (this.currentAction) {
        case 'pause':
          this.loading = true
          CampaignService.changeStatus({
            status: 'PAUSED',
            sendingId: this.currentItem.sendingId,
          }).then(
            (response) => {
              this.show = false
              EventBus.$emit('reloadTable', 'PAUSED')
            },
            (error) => {
              CampaignService.errorResponse(
                error.response.status,
                this.$t('Error al intentar pausar la campaña'),
                this.$t(error.response.data.message),
              )
            },
          )
          .finally(() => {
            this.loading = false
          })
          break
        case 'resume':
          this.loading = true
          CampaignService.changeStatus({
            status: 'SENDING',
            sendingId: this.currentItem.sendingId,
          }).then(
            (response) => {
              this.show = false
              EventBus.$emit('reloadTable', 'SENDING')
            },
            (error) => {
              CampaignService.errorResponse(
                error.response.status,
                this.$t('Error al intentar renaudar la campaña'),
                this.$t(error.response.data.message),
              )
            },
          )
          .finally(() => {
            this.loading = false
          })
          break
        case 'cancel':
        case 'stop':
          this.loading = true
          CampaignService.changeStatus({
            status: 'CANCELLED',
            sendingId: this.currentItem.sendingId,
          }).then(
            (response) => {
              this.show = false
              EventBus.$emit('reloadTable', 'CANCELLED')
            },
            (error) => {
              CampaignService.errorResponse(
                error.response.status,
                this.$t('Error al intentar renaudar la campaña'),
                this.$t(error.response.data.message),
              )
            },
          )
          .finally(() => {
            this.loading = false
          })
          break
        case 'duplicate':
          CampaignService.duplicate(params).then((data) => {
            switch (parseInt(data.channel)) {
              case ChannelType.SMS_ID:
              case ChannelType.MAILING_ID:
                this.$router.push({ path: '/campaign/' + data.slug + '/edit/' + data.campaign.id + '?type=' + data.campaign.campaign_subtype })
                break
              case ChannelType.VOICE_INTERACTIVE_ID:
                this.$router.push({ path: '/campaign/voice/edit/' + data.campaign.id })
                break
              case ChannelType.WHATSAPP_ID:
                this.$router.push({ path: '/campaign/whatsapp/edit/' + data.campaign.id })
                break
            }
          }, (error) => {
            this.loading = false
            if (error.response.status === 422) {
              let errorsAlert = ''
              const errors = error.response.data
              for (const index in errors) {
                errorsAlert += this.$errorMessages.getValidationMessage(errors[index].error, index, errors[index].values) + '<br>'
              }
              EventBus.$emit('showAlert', 'danger', errorsAlert)
            }
            if (error.response.status === 400) {
              EventBus.$emit('showAlert', 'danger', this.$errorMessages.getMessage(error.response.data.code))
            }
          })
          break
        case 'delete':
          CampaignService.delete(this.currentItem.sendingId).then((data) => {
            switch (parseInt(data.channel)) {
              case ChannelType.SMS_ID:
              case ChannelType.MAILING_ID:
                this.$router.push('/campaign/' + data.slug + '/edit/' + data.campaign.id + '?type=' + data.campaign.campaign_subtype)
                break
              case 7:
                this.$router.push('/campaign/voice/edit/' + data.campaign.id)
                break
            }
            this.show = false
            EventBus.$emit('reloadTable', 'DELETED')
            EventBus.$emit('showAlert', 'success', this.$t('El envío de la campaña  se ha eliminado con éxito'))
          }, (error) => {
            this.loading = false
            if (error.response.status === 400) {
              EventBus.$emit('showAlert', 'danger', this.$errorMessages.getMessage(error.response.data.code))
            }
          })
          break
      }
    },

    setAction (data) {
      this.currentItem = data.item
      this.currentAction = data.action
      this.modalData = this.strings[data.action]
      this.toggle()
    },
  },
  created () {
    EventBus.$on('toggleAction', (data) => {
     this.setAction(data)
    })
  },
}
