import Funnel from '@/components/Graphs/Funnel/Funnel.vue'
import { GlobalControl } from '@/classes/stats/GlobalControl.js'
import ImpactCost from '@/components/Graphs/ImpactCost/ImpactCost.vue'
import DateRange from '@/components/DateRange/DateRange.vue'
import EventBus from '@/util/EventBus.js'
import CardStats from './CardStats.vue'
import moment from 'moment'

export default {
  name: 'GeneralStats',
  components: { Funnel, ImpactCost, DateRange, CardStats },
  data () {
    return {
      globalControl: new GlobalControl('2023-01-01'),
      dates: [],
      textDates: '',
      showFunnel: true,
    }
  },
  watch: {
    globalControl: {
      handler: function () {
        this.setGlobalLabel()
        EventBus.$emit('updateImpactCostStatisticsGlobalControl')
        EventBus.$emit('updateFunnelStatisticsGlobalControl')
      },
      deep: true,
    },
  },
  methods: {
    changeGlobalControl (dates) {
      if (Date.parse(dates[0]) > Date.parse(dates[1])) {
        this.globalControl.initDate = dates[1]
        this.globalControl.endDate = dates[0]
      } else {
        this.globalControl.initDate = dates[0]
        this.globalControl.endDate = dates[1]
      }
    },
    setInitialDateRange () {
      this.globalControl.initDate = moment().subtract(1, 'years').format('YYYY-MM-DD')
      this.globalControl.endDate = moment().format('YYYY-MM-DD')
    },
    setGlobalLabel () {
      this.globalControl.switchLabel = this.globalControl.globals ? this.$t('Datos globales').toString() : this.$t('Datos de mis campañas').toString()
    },
  },
  created () {
    this.setInitialDateRange()
    this.setGlobalLabel()
    EventBus.$on('changedLanguage', () => {
      this.showFunnel = false
      setTimeout(() => {
        this.showFunnel = true
      }, 500)
    })
  },

}
