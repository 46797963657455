export default {
  name: 'HomeModalBillingProfile',
  emits: [
    'close',
    'close-forever',
  ],
  props: {
    toggle: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {}
  },
  methods: {
    emitClose () {
      this.$emit('close')
    },
    emitCloseAndGoToBillingProfile () {
      this.$emit('close')
      this.$router.push({ name: 'billingProfile' })
    },
    emitCloseForever () {
      this.$emit('close-forever')
    },
  },
}
