export default {
  name: 'HomeModalCampaignValidate',
  props: {
    toogle: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      defaul: false,
    },
  },
  data () {
    return {}
  },
  methods: {
    emitClose () {
      this.$emit('close')
    },
    emitAccepted () {
      this.$emit('accepted')
    },
  },
}
