import { Service } from './service'

const PATH = 'rss/'

class RssFeedService extends Service {
  getRssNewsFeed (params = {}) {
    return this.get(PATH + 'news-feed', params)
  }
}

export default new RssFeedService()
