import VueApexCharts from 'vue-apexcharts'
import HelpText from '@/components/Layout/HelpText/HelpText.vue'

export default {
  props: ['campaginDataSource', 'campaign', 'height'],
  name: 'UserInteractionMailing',
  components: { apexchart: VueApexCharts, HelpText },
  data: function () {
    return {
      ready: true,
      heightDef: this.height ? this.height : 220,
      openedUniqueRate: 0,
      clickedUniquePercentage: 0,
      softBouncedUniquePercentage: 0,
      hardBouncedUniquePercentage: 0,
      unsubscribedTotalPercentage: 0,

      deliveredTotal: 0,
      openedUnique: 0,
      openedTotal: 0,
      clickedUnique: 0,
      softBouncedUnique: 0,
      hardBouncedUnique: 0,
      unsubscribedTotal: 0,
      totalClick: 0,
      clickUnique: 0,
      clicksRatio: 0,
    }
  },
  methods: {
    setBarChart (data) {
        this.deliveredTotal = data.delivered_total ? parseInt(data.delivered_total) : 0
        this.openedUnique = data.opened_unique ? parseInt(data.opened_unique) : 0
        this.totalClick = data.clicked_total ? parseInt(data.clicked_total) : 0
        this.clickUnique = data.clicked_unique ? parseInt(data.clicked_unique) : 0

        this.openedTotal = data.opened_total ? parseInt(data.opened_total) : this.openedTotal
        this.clickedUnique = parseInt(data.clicked_unique)

        if (this.deliveredTotal > 0) {
          this.openedUniqueRate = (this.openedUnique / this.deliveredTotal * 100).toFixed(1)
          this.clicksRatio = (this.totalClick / this.deliveredTotal * 100).toFixed(2)
        }
    },
  },
  created () {
    if (this.campaginDataSource.data[0]) {
      this.setBarChart(this.campaginDataSource.data[0])
    }
  },
}
