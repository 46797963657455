import VueApexCharts from 'vue-apexcharts'
import GeneralStatsService from '@/services/general-stats.service'
import EventBus from '@/util/EventBus.js'

export default {
  name: 'ImpactCost',
  props: ['globalControl'],
  data () {
    return {
      selectedChannel: '',
      daysOrHour: true,
      switchDiaHoraLabel: 'Por día de la semana',
      xaxisOptions: {
        day: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'],
        hour: ['09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '108:00', '109:00', '20:00', '21:00'],
      },
      options: {
        chart: {
          id: 'vuechart-example',
          type: 'heatmap',
        },
        dataLabels: {
          enabled: false,
        },
        colors: [this.$smsColor],
        yaxis: {
          categories: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'],
        },
      },
      series: [],
      impactDays: [],
    }
  },
  components: { apexchart: VueApexCharts },
  watch: {
      daysOrHour () {
          this.getimpactCostStats()
      },
  },
  computed: {
      smsSeries: function () {
          const series = []
          const reversed = Object.keys(this.impactDays.sms).reverse()
          for (const day of reversed) {
              series.push(
                {
                    name: this.getDay(day),
                    data: this.setHoursData(this.impactDays.sms[day]),
                },
              )
          }
         return series
      },
      mailSeries: function () {
          const series = []
          const reversed = Object.keys(this.impactDays.sms).reverse()
          for (const day of reversed) {
              series.push(
                {
                    name: this.getDay(day),
                    data: this.setHoursData(this.impactDays.mail[day]),
                },
              )
          }
          return series
      },
      voiceSeries: function () {
          const series = []
          const reversed = Object.keys(this.impactDays.sms).reverse()
          for (const day of reversed) {
              series.push(
                {
                    name: this.getDay(day),
                    data: this.setHoursData(this.impactDays.voice[day]),
                },
              )
          }
          return series
      },
      whatsappSeries: function () {
          const series = []
          const reversed = Object.keys(this.impactDays.sms).reverse()
          for (const day of reversed) {
              series.push(
                  {
                      name: this.getDay(day),
                      data: this.setHoursData(this.impactDays.whatsapp[day]),
                  },
              )
          }
          return series
      },
  },
  methods: {
    setHoursData (data) {
        const hoursData = []
        for (const value in data) {
          let percentage = 0
          if (data[value].sent > 0) {
            percentage = (parseInt(data[value].event) * 100) / parseInt(data[value].sent)
          }
          hoursData.push(
            {
              x: value,
              y: percentage.toFixed(2),
            },
          )
        }
        return hoursData
   },
    getDay (day) {
      let dayName = ''
      switch (parseInt(day)) {
          case 1:
              dayName = this.$t('Lunes')
              break
          case 2:
              dayName = this.$t('Martes')
              break
          case 3:
              dayName = this.$t('Miércoles')
              break
          case 4:
              dayName = this.$t('Jueves')
              break
          case 5:
              dayName = this.$t('Viernes')
              break
          case 6:
              dayName = this.$t('Sábado')
              break
          case 7:
              dayName = this.$t('Domingo')
              break
      }
      return dayName
    },
    updateChannel () {
      let colors = []
      switch (this.selectedChannel) {
        case 'SMS':
          colors = [this.$smsColor]
          this.series = this.smsSeries
          break
        case 'Mail':
          this.series = this.mailSeries
          colors = [this.$emailColor]
          break
        case 'Voz':
          this.series = this.voiceSeries
          colors = [this.$voiceColor]
          break
      case 'WhatsApp':
          this.series = this.whatsappSeries
          colors = [this.$whatsappColor]
          break
      }
      this.options = {
        chart: {
          id: 'vuechart-example',
          type: 'heatmap',
        },
        dataLabels: {
          enabled: false,
        },
        colors: colors,
        yaxis: {
          categories: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'],
        },
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex]

            return '<span class="pa-3">' +
              '<b>' + w.globals.initialSeries[seriesIndex].name + ' - ' + data.x + '</b>: ' + data.y + '%' +
              '</span>'
          },
       },
      }
    },
    sumElements (arrayOfValues) {
      return arrayOfValues.reduce((a, b) => a + b)
    },

    updateChartData (data) {
    },
    getimpactCostStats () {
      if (!this.globalControl.initDate || !this.globalControl.endDate) {
        return
      }

      GeneralStatsService.getimpactCostStats({
          initDate: this.globalControl.initDate,
          endDate: this.globalControl.endDate,
          globals: this.globalControl.globals,
          daysOrHour: this.daysOrHour,
        }).then((data) => {
          this.updateChartData(data)
      })
    },

    getImpactDaysStats () {
      GeneralStatsService.getImpactDaysStats().then((data) => {
        this.setHistogramData(data)
      })
    },

    setHistogramData (data) {
        this.impactDays = data
        this.selectedChannel = 'SMS'
        this.updateChannel()
    },

  },
  created () {
    this.getimpactCostStats()
    this.getImpactDaysStats()
    EventBus.$on('updateImpactCostStatisticsGlobalControl', () => {
      this.getimpactCostStats()
    })
  },
}
