import VueApexCharts from 'vue-apexcharts'
import { ImpactCost } from '@/classes/stats/ImpactCost.js'
import GeneralStatsService from '@/services/general-stats.service'

export default {
  name: 'ImpactCostVoice',
  props: ['campaignId'],
  data () {
    return {
      switchDiaHoraLabel: 'Por día de la semana',
      xaxisOptions: {
        day: [
          this.$t('Lunes'),
          this.$t('Martes'),
          this.$t('Miércoles'),
          this.$t('Jueves'),
          this.$t('Viernes'),
          this.$t('Sábado'),
          this.$t('Domingo'),
        ],
        hour: ['9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00'],
      },
      options: {
        chart: {
          id: 'vuechart-example',
          type: 'area',
        },
        xaxis: {
          categories: [
            this.$t('Lunes'),
            this.$t('Martes'),
            this.$t('Miércoles'),
            this.$t('Jueves'),
            this.$t('Viernes'),
            this.$t('Sábado'),
            this.$t('Domingo'),
          ],
        },
      },
      impactCost: new ImpactCost(),
      ready: false,
      series: [],
    }
  },
  components: { apexchart: VueApexCharts },
  watch: {
      impactCost: {
        handler: function () {
          this.getimpactCostStats()
        },
        deep: true,
      },
  },
  methods: {
    resetReady () {
      this.ready = false
      setTimeout(() => {
        this.ready = true
      })
    },

    updateChartOptions () {
      this.switchDiaHoraLabel = this.impactCost.daysOrHour ? this.$t('Por día de la semana') : this.$t('Por hora del día')
      this.options.xaxis.categories = this.impactCost.daysOrHour ? this.xaxisOptions.day : this.xaxisOptions.hour
      setTimeout(() => {
        if (this.$refs.costChart) {
          this.$refs.costChart.updateOptions(this.options, true, true)
        }
      })
    },
    updateImpactCostChartData (data) {
      this.series = [
        {
          name: 'Voz',
          color: '#7795FF',
          data: data,
        },
      ]
      this.updateChartOptions()
    },
    getimpactCostStats () {
      GeneralStatsService.getImpactDaysStatsVoiceCampaign({
          globals: false,
          daysOrHour: this.impactCost.daysOrHour,
          campaignId: this.campaignId,
        }).then((data) => {
          this.updateImpactCostChartData(data)
      })
    },

  },
  created () {
    this.getimpactCostStats()
  },
}
