<template>
  <div class="container-fluid">
    <div class="header-body">
      <div class="row">
        <div class="col-xl-4 col-lg-6">
          <div class="stats-card card-stats mb-4 mb-xl-0">
            <div class="card-body-stats">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">
                    {{ $t('Campañas') }}
                  </h5>
                  <span class="h1 font-weight-bold mb-0">{{ campaignsCount }}</span>
                </div>
                <div class="col-auto">
                  <div class="icon icon-shape bg-danger text-white rounded-circle shadow">
                    <i class="fas fa-copy" />
                  </div>
                </div>
              </div>
              <p class="mt-3 mb-0 text-muted text-sm float-right">
                <v-btn
                  class="btn_submenu"
                  link
                  :to="{ path: '/' }"
                >
                  <v-icon left>
                    fas fa-mail-bulk
                  </v-icon> {{ $t('Ver campañas') }}
                </v-btn>
              </p>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6">
          <div class="stats-card card-stats mb-4 mb-xl-0">
            <div class="card-body-stats">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">
                    {{ $t('Saldo') }}
                  </h5>
                  <span class="h1 font-weight-bold mb-0">{{ userPlan }} €</span>
                </div>
                <div class="col-auto">
                  <div class="icon icon-shape bg-warning text-white rounded-circle shadow">
                    <i class="fas fa-dollar-sign" />
                  </div>
                </div>
              </div>
              <p class="mt-3 mb-0 text-muted text-sm float-right">
                <v-btn
                  :disabled="!$permissions.hasPermission('create-payments')"
                  class="btn_submenu"
                  link
                  :to="{ path: '/payments' }"
                >
                  <v-icon left>
                    fas fa-credit-card
                  </v-icon> {{ $t('Añadir saldo') }}
                </v-btn>
              </p>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6">
          <div class="stats-card card-stats mb-4 mb-xl-0">
            <div class="card-body-stats">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">
                    {{ $t('Facturas') }}
                  </h5>
                  <span class="h1 font-weight-bold mb-0">{{ invoicesCount }}</span>
                </div>
                <div class="col-auto">
                  <div class="icon icon-shape bg-info text-white rounded-circle shadow">
                    <i class="fas fa-file-invoice-dollar" />
                  </div>
                </div>
              </div>
              <p class="mt-3 mb-0 text-muted text-sm float-right">
                <v-btn
                  class="btn_submenu"
                  link
                  :to="{ path: '/users/billing' }"
                >
                  <v-icon left>
                    fas fa-list-alt
                  </v-icon> {{ $t('Ver facturas') }}
                </v-btn>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import AccountService from '@/services/account.service.js'

  export default {
    name: 'CardStats',
    components: {},
    data () {
      return {
        campaignsCount: 0,
        contactsCount: 0,
        invoicesCount: 0,
        userPlan: 0,
      }
    },
    created () {
      this.getMainStats()
    },
    methods: {
      getMainStats () {
        AccountService.getMainStats().then((data) => {
          this.campaignsCount = data.campaignsCount
          this.contactsCount = data.contactsCount
          this.invoicesCount = data.invoicesCount
          this.userPlan = 0
        })
      },
    },
  }

</script>

<style>
.h1 {
  font-size: 20px;
}

.stats-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  border: 1px solid rgba(0, 0, 0, .05);
  border-radius: .375rem;
  background-color: #fff;
  background-clip: border-box;
}


.card-stats .card-body-stats {
  padding: 1rem 1.5rem;
}


.icon {
  width: 3rem;
  height: 3rem;
}

.icon i {
  font-size: 2.25rem;
}

.icon-shape {
  display: inline-flex;
  padding: 12px;
  text-align: center;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.icon-shape i {
  font-size: 1.25rem;
}
</style>
